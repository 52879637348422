import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { ApmService } from '@elastic/apm-rum-angular';

import { environment } from 'src/environments/environment';

// Import containers
import { GerencieLayoutComponent } from 'src/app/containers/gerencie-layout';

const APP_CONTAINERS = [
  GerencieLayoutComponent
];

// Modules
import { CoreModule } from 'src/app/core/core.module';
import { GerencieRoutingModule } from './gerencie.routing';

import { AppComponent } from '../shared/app.component';


const APP_MODULES = [
  CoreModule,
];

@NgModule({
  imports: [
    GerencieRoutingModule,
    ...APP_MODULES,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
  ],
  providers: [
    // ApmService
  ],
  bootstrap: [AppComponent]
})
export class GerencieModule {
  constructor(
    // apmService: ApmService,
  ) {
    // Agent API is exposed through this apm instance
    // const apm = apmService.init({
    //   serviceName: 'gerencie',
    //   serverUrl: 'https://56dc5ee1bcad47068a2be4b2bdc25340.apm.us-central1.gcp.cloud.es.io:443',
    //   environment: environment.production ? 'production' : 'development'
    // });
  }
}
