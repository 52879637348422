export const product = (vets: Array<any>): any[] => {
  if (vets.length > 0) {
    const args = Array.prototype.slice.call(vets); // makes array from arguments
    return args.reduce((accumulator, value) => {
      const tmp = [];
      accumulator.forEach(a0 => {
        value.forEach(a1 => tmp.push(a0.concat(a1)));
      });
      return tmp;
    }, [[]]);
  }

  return [];
};
