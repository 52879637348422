import { INavData } from "@coreui/angular";

export const navItems: INavData[] = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "icon-speedometer",
  },
  // ---- ATENDIMENTO ----
  {
    name: "Atendimento",
    icon: "far fa-calendar",
    url: "/schedule",
    attributes: { permission: { namespace: "schedule" } },
    children: [
      {
        name: "Agendas",
        icon: "far fa-calendar",
        url: "/schedule/schedule",
        attributes: {
          permission: { namespace: "schedule", action: "schedule_list" },
        },
      },
      {
        name: "Atender",
        icon: "fas fa-user-clock",
        url: "/schedule/attend",
        attributes: {
          permission: { namespace: "schedule", action: "attendance_create" },
        },
      },
      {
        name: "Atendimentos",
        icon: "fas fa-user-check",
        url: "/schedule/attendance",
        attributes: {
          permission: { namespace: "schedule", action: "attendance_list" },
        },
      },
      {
        name: "Diversos",
        icon: "fas fa-bars",
        url: ".",
        children: [
          {
            name: "Categorias",
            url: "/schedule/procedure-category",
            icon: "fas fa-layer-group",
            attributes: {
              permission: {
                namespace: "schedule",
                action: "procedure_category_list",
              },
            },
          },
          {
            name: "Convênios",
            url: "/schedule/covenant",
            icon: "fas fa-hands-helping",
            attributes: {
              permission: { namespace: "schedule", action: "covenant_list" },
            },
          },
          {
            name: "Modelo Atendimento",
            url: "/schedule/attendance-template",
            icon: "fab fa-buromobelexperte",
            attributes: {
              permission: {
                namespace: "schedule",
                action: "attendance_template_list",
              },
            },
          },
          {
            name: "Procedimentos",
            url: "/schedule/procedure",
            icon: "fa fa-user-md",
            attributes: {
              permission: { namespace: "schedule", action: "procedure_list" },
            },
          },
        ],
      },
      {
        name: "Relatórios",
        url: "/schedule/report",
        icon: "fas fa-print",
        attributes: { permission: { namespace: "schedule" } },
      },
    ],
  },

  // ---- Documentos ----
  {
    name: "Documentos",
    icon: "far fa-file-alt",
    url: "/ecm",
    attributes: { permission: { namespace: "ecm" } },
    children: [
      {
        name: "Dashboard",
        url: "/ecm/dashboard",
        icon: "icon-speedometer",
        attributes: { permission: { namespace: "ecm" } },
      },
      {
        name: "Documentos",
        url: "/ecm/document",
        icon: "far fa-file-alt",
        attributes: { permission: { namespace: "ecm", action: "file_list" } },
      },
      {
        name: "Monitoramento",
        url: "/ecm/watch",
        icon: "fas fa-binoculars",
        attributes: {
          permission: { namespace: "ecm", action: "watch_log_list" },
        },
      },
      {
        name: "Diversos",
        icon: "fas fa-bars",
        url: ".",
        children: [
          // {
          //   name: 'Monitoramento',
          //   url: '/ecm/watch',
          //   icon: 'fas fa-tasks',
          //   attributes: { permission: { namespace: 'ecm', action: 'watch_log_list' } },
          // },
          {
            name: "Índices",
            url: "/ecm/template",
            icon: "far fa-copy",
            attributes: {
              permission: { namespace: "ecm", action: "template_list" },
            },
          },
          {
            name: "Upload",
            url: "/ecm/upload",
            icon: "fas fa-cloud-upload-alt",
            attributes: {
              permission: { namespace: "ecm", action: "file_create" },
            },
          },
        ],
      },
      {
        name: "Relatório",
        url: "/ecm/report",
        icon: "fas fa-print",
        attributes: { permission: { namespace: "ecm", action: "file_view" } },
      },
    ],
  },

  // ---- Financeiro ----
  {
    name: "Financeiro",
    icon: "fas fa-wallet",
    url: "/finance",
    attributes: { permission: { namespace: "finance" } },
    children: [
      {
        name: "Banco / Caixa",
        url: "/finance/lancamento",
        icon: "fas fa-wallet",
        attributes: {
          permission: { namespace: "finance", action: "lancamento_list" },
          headerNav: true,
        },
      },
      // {
      //   name: 'Contas',
      //   icon: 'fas fa-file-invoice-dollar',
      //   url: '.',
      //   children: [

      //   ]
      // },
      {
        name: "Contas a Pagar",
        url: "/finance/conta-pagar",
        icon: "fas fa-money-bill-wave",
        attributes: {
          permission: { namespace: "finance", action: "conta_pagar_list" },
        },
      },
      {
        name: "Contas a Receber",
        url: "/finance/conta-receber",
        icon: "fas fa-hand-holding-usd",
        attributes: {
          permission: { namespace: "finance", action: "conta_receber_list" },
          headerNav: true,
        },
      },
      {
        name: "Cheques",
        icon: "fas fa-money-check-alt",
        url: "/finance/cheque/",
        attributes: { permission: { namespace: "finance", action: "cheque" } },
      },
      {
        name: "Troca Arquivos",
        icon: "fas fa-file-export",
        url: ".",
        children: [
          {
            name: "Boletos",
            icon: "fas fa-barcode",
            url: ".",
            children: [
              {
                name: "Remessa",
                url: "/finance/boleto/remessa",
                icon: "fa fa-upload",
                attributes: {
                  permission: { namespace: "finance", action: "remessa_list" },
                },
              },
              {
                name: "Retorno",
                url: "/finance/boleto/retorno",
                icon: "fa fa-download",
                attributes: {
                  permission: { namespace: "finance", action: "retorno_list" },
                },
              },
            ],
          },
          {
            name: "Deb. Conta",
            icon: "fas fa-file-invoice-dollar",
            url: ".",
            children: [
              {
                name: "Remessa",
                url: "/finance/debito-conta/remessa",
                icon: "fa fa-upload",
                attributes: {
                  permission: { namespace: "finance", action: "debito_conta_list" },
                },
              },
              {
                name: "Retorno",
                url: "/finance/debito-conta/retorno",
                icon: "fa fa-download",
                attributes: {
                  permission: { namespace: "finance", action: "debito_conta_list" },
                },
              },
            ],
          },
        ],
      },
      {
        name: "Diversos",
        icon: "fas fa-bars",
        url: ".",
        children: [
          {
            name: "Banco / Caixa",
            icon: "fas fa-wallet",
            url: ".",
            children: [
              {
                name: "Conta",
                url: "/finance/conta",
                attributes: {
                  permission: { namespace: "finance", action: "conta_list" },
                },
              },
              {
                name: "Carteira Cobrança",
                url: "/finance/carteira-cobranca",
                attributes: {
                  permission: {
                    namespace: "finance",
                    action: "carteira_cobranca_list",
                  },
                },
              },
              {
                name: "Tipo Conta",
                url: "/finance/tipo-conta",
                attributes: {
                  permission: {
                    namespace: "finance",
                    action: "conta_tipo_list",
                  },
                },
              },
            ],
          },
          {
            name: "Cartões",
            icon: "fas fa-credit-card",
            url: ".",
            children: [
              {
                name: "Meus Cartões",
                url: "/finance/cartao",
                icon: "far fa-address-card",
                attributes: {
                  permission: { namespace: "finance", action: "cartao_list" },
                },
              },
              {
                name: "Bandeiras",
                url: "/finance/cartao-bandeira",
                icon: "fab fa-cc-visa",
                attributes: {
                  permission: {
                    namespace: "finance",
                    action: "cartao_bandeira_list",
                  },
                },
              },
              {
                name: "Contratos",
                url: "/finance/cartao-contrato",
                icon: "fas fa-file-signature",
                attributes: {
                  permission: {
                    namespace: "finance",
                    action: "cartao_contrato_list",
                  },
                },
              },
            ],
          },
          {
            name: "Categorias",
            icon: "fas fa-layer-group",
            url: ".",
            children: [
              {
                name: "Entradas",
                url: "/finance/categoria-entrada",
                icon: "fas fa-arrow-down",
                attributes: {
                  permission: {
                    namespace: "finance",
                    action: "categoria_entrada_list",
                  },
                },
              },
              {
                name: "Saídas",
                url: "/finance/categoria-saida",
                icon: "fas fa-arrow-up",
                attributes: {
                  permission: {
                    namespace: "finance",
                    action: "categoria_saida_list",
                  },
                },
              },
              {
                name: "Padrão",
                url: "/finance/categoria-padrao",
                icon: "fas fa-sliders-h",
                attributes: {
                  permission: {
                    namespace: "finance",
                    action: "categoria_padrao_view",
                  },
                },
              },
            ],
          },
          {
            name: "Formas",
            icon: "far fa-handshake",
            url: ".",
            children: [
              {
                name: "Pagamento",
                url: "/finance/forma-pagamento",
                icon: "fas fa-arrow-up",
                attributes: {
                  permission: {
                    namespace: "finance",
                    action: "forma_pagamento_list",
                  },
                },
              },
              {
                name: "Recebimento",
                url: "/finance/forma-recebimento",
                icon: "fas fa-arrow-down",
                attributes: {
                  permission: {
                    namespace: "finance",
                    action: "forma_recebimento_list",
                  },
                },
              },
            ],
          },
          {
            name: "Plano",
            url: "/finance/plane",
            icon: "fab fa-buromobelexperte",
            attributes: {
              permission: { namespace: "finance", action: "plane_list" },
            },
          },
        ],
      },
      {
        name: "Pag. Recorrente",
        icon: "far fa-calendar-alt",
        url: ".",
        children: [
          {
            name: "Gerar Boletos",
            url: "/finance/mensalidade-gerar",
            icon: "fas fa-barcode",
            attributes: {
              permission: {
                namespace: "finance",
                action: "gerar_mensalidade_list",
              },
            },
          },
          {
            name: "Receber",
            url: "/finance/mensalidade-receber",
            icon: "fas fa-hand-holding-usd",
            attributes: {
              permission: {
                namespace: "finance",
                action: "monthly_payment_view",
              },
            },
          },
        ],
      },
      {
        name: "Relatórios",
        url: "/finance/report",
        icon: "fas fa-print",
        attributes: { permission: { namespace: "finance" } },
      },
    ],
  },

  // ---- Faturamento ----
  {
    name: "Faturamento",
    icon: "fas fa-file-invoice-dollar",
    url: "/faturamento",
    attributes: { permission: { namespace: "faturamento" } },
    children: [
      {
        name: "Vendas",
        icon: "fas fa-receipt",
        url: "/faturamento/sale",
        attributes: {
          permission: { namespace: "faturamento", action: "sale_list" },
          headerNav: true,
        },
      },
      {
        name: "Relatórios",
        url: "/faturamento/report",
        icon: "fas fa-print",
        attributes: { permission: { namespace: "faturamento" } },
      },
    ],
  },

  // // ---- Imóveis ----
  // {
  //   name: 'Imóveis',
  //   icon: 'fas fa-home',
  //   url: '/imoveis',
  //   attributes: { permission: { namespace: 'imoveis' } },
  //   children: [
  //     {
  //       name: 'Propriedades',
  //       url: '/imoveis/propriedades',
  //       icon: 'fas fa-map-marked-alt',
  //       attributes: { permission: { namespace: 'imoveis', action: 'imovel_list' } },
  //     },
  //   ]
  // },

  // ---- Patrimônio ----
  {
    name: "Patrimônio",
    icon: "fas fa-tv",
    url: "/patrimonio",
    attributes: { permission: { namespace: "patrimonio" } },
    children: [
      {
        name: "Bem",
        url: "/patrimonio/bem",
        icon: "fas fa-tv",
        attributes: {
          permission: { namespace: "patrimonio", action: "bem_list" },
        },
      },
      {
        name: "Diversos",
        icon: "fas fa-bars",
        url: ".",
        children: [
          {
            name: "Tipo de Bem",
            url: "/patrimonio/tipo-bem",
            icon: "fas fa-vector-square",
            attributes: {
              permission: { namespace: "patrimonio", action: "tipo_bem_list" },
            },
          },
          {
            name: "Grupo",
            url: "/patrimonio/grupo",
            icon: "far fa-object-group",
            attributes: {
              permission: { namespace: "patrimonio", action: "grupo_list" },
            },
          },
          {
            name: "Estado de Conservação",
            url: "/patrimonio/estado-conservacao",
            icon: "fas fa-hourglass-half",
            attributes: {
              permission: {
                namespace: "patrimonio",
                action: "estado_conservacao_list",
              },
            },
          },
          {
            name: "Tipo de Aquisição",
            url: "/patrimonio/tipo-aquisicao",
            icon: "fas fa-funnel-dollar",
            attributes: {
              permission: {
                namespace: "patrimonio",
                action: "tipo_aquisicao_list",
              },
            },
          },
          {
            name: "Tipo de Movimentação",
            url: "/patrimonio/tipo-movimentacao",
            icon: "fas fa-expand-arrows-alt",
            attributes: {
              permission: {
                namespace: "patrimonio",
                action: "tipo_movimentacao_list",
              },
            },
          },
        ],
      },
    ],
  },

  // ---- Pessoas ----
  {
    name: "Pessoas",
    icon: "fas fa-user-friends",
    url: "/cadastro",
    attributes: { permission: { namespace: "people" } },
    children: [
      {
        name: "Contatos",
        url: "/cadastro/contact",
        icon: "fas fa-user",
        attributes: {
          permission: { namespace: "people", action: "contact_list" },
        },
      },
      {
        name: "Pessoas",
        url: "/cadastro/person",
        icon: "fas fa-user-friends",
        attributes: {
          permission: { namespace: "people", action: "person_list" },
        },
      },
      {
        name: "Clientes",
        url: "/cadastro/customer",
        icon: "fas fa-user-circle",
        attributes: {
          permission: { namespace: "people", action: "customer_list" },
          headerNav: true,
        },
      },
      {
        name: "Membros",
        url: "/cadastro/member",
        icon: "fa fa-users",
        attributes: {
          permission: { namespace: "associated", action: "member_list" },
          headerNav: true,
        },
      },
      {
        name: "Sub. Cadastros",
        icon: "fas fa-bars",
        url: ".",
        children: [
          {
            name: "Diretores",
            url: "/cadastro/direction",
            icon: "fas fa-user-tie",
            attributes: {
              permission: { namespace: "associated", action: "direction_list" },
            },
          },
          {
            name: "Empregadores",
            url: "/cadastro/employer",
            icon: "fas fa-user-tie",
            attributes: {
              permission: { namespace: "people", action: "employer_list" },
            },
          },
          {
            name: "Fornecedores",
            url: "/cadastro/person-provider",
            icon: "fas fa-dolly",
            attributes: {
              permission: {
                namespace: "people",
                action: "person_provider_list",
              },
            },
          },
          {
            name: "Funcionários",
            url: "/cadastro/employee",
            icon: "fas fa-user-cog",
            attributes: {
              permission: { namespace: "people", action: "employee_list" },
            },
          },
          {
            name: "Profissionais",
            url: "/cadastro/professional",
            icon: "fa fa-briefcase",
            attributes: {
              permission: { namespace: "people", action: "profissional_list" },
            },
          },
          {
            name: "Cargo",
            url: "/cadastro/job-role",
            icon: "far fa-circle",
            attributes: {
              permission: { namespace: "people", action: "job_role_list" },
            },
          },
          {
            name: "Categorias",
            url: "/cadastro/categorias",
            icon: "far fa-circle",
            attributes: {
              permission: { namespace: "people", action: "category_list" },
            },
          },
          {
            name: "Comunidade",
            url: "/cadastro/community",
            icon: "far fa-circle",
            attributes: {
              permission: { namespace: "people", action: "comunity_list" },
            },
          },
          {
            name: "Parentesco",
            url: "/cadastro/kinship",
            icon: "far fa-circle",
            attributes: {
              permission: { namespace: "people", action: "kinship_list" },
            },
          },
          {
            name: "Profissão",
            url: "/cadastro/occupation",
            icon: "far fa-circle",
            attributes: {
              permission: { namespace: "people", action: "occupation_list" },
            },
          },
          {
            name: "Setor",
            url: "/cadastro/sector",
            icon: "far fa-circle",
            attributes: {
              permission: { namespace: "people", action: "sector_list" },
            },
          },
        ],
      },
      {
        name: "Relatórios",
        url: "/cadastro/report",
        icon: "fas fa-print",
        attributes: { permission: { namespace: "people" } },
      },
    ],
  },

  // ---- Produtos e Serviços ----
  {
    name: "Produtos / Serviços",
    icon: "fas fa-box",
    url: "/stock",
    attributes: { permission: { namespace: "stock" } },
    children: [
      {
        name: "Produtos",
        icon: "fas fa-boxes",
        url: ".",
        children: [
          {
            name: "Produtos",
            url: "/stock/product",
            icon: "fas fa-box-open",
            attributes: {
              permission: { namespace: "stock", action: "product_list" },
            },
          },
          {
            name: "Categorias",
            url: "/stock/product-category",
            icon: "fas fa-stream",
            attributes: {
              permission: {
                namespace: "stock",
                action: "product_category_list",
              },
            },
          },
          {
            name: "Unidades",
            url: "/stock/product-unit",
            icon: "fas fa-balance-scale",
            attributes: {
              permission: { namespace: "stock", action: "product_unit_list" },
            },
          },
          {
            name: "Marcas",
            url: "/stock/brand",
            icon: "fas fa-bullhorn",
            attributes: {
              permission: { namespace: "stock", action: "brand_list" },
            },
          },
        ],
      },
      {
        name: "Serviços",
        icon: "fas fa-people-carry",
        url: ".",
        children: [
          {
            name: "Serviços",
            url: "/stock/service",
            icon: "fas fa-people-carry",
            attributes: {
              permission: { namespace: "stock", action: "service_list" },
            },
          },
          {
            name: "Categorias",
            url: "/stock/service-category",
            icon: "fas fa-stream",
            attributes: {
              permission: {
                namespace: "stock",
                action: "service_category_list",
              },
            },
          },
        ],
      },
      {
        name: "Modelo de Opções",
        icon: "fab fa-buromobelexperte",
        url: "/stock/template-option",
        attributes: {
          permission: { namespace: "stock", action: "template_option_list" },
        },
      },
    ],
  },

  // ---- SMS ----
  {
    name: "SMS",
    icon: "fas fa-sms",
    url: "/sms",
    attributes: { permission: { namespace: "sms" } },
    children: [
      {
        name: "Dashboard",
        url: "/sms/dashboard",
        icon: "icon-speedometer",
        attributes: { permission: { namespace: "sms" } },
      },
      {
        name: "Envios",
        url: "/sms/send",
        icon: "far fa-paper-plane",
        attributes: { permission: { namespace: "sms", action: "sms_create" } },
      },
      {
        name: "Diversos",
        icon: "fas fa-bars",
        url: ".",
        children: [
          {
            name: "Modelos",
            url: "/sms/template",
            icon: "fab fa-wpforms",
            attributes: {
              permission: { namespace: "sms", action: "template_list" },
            },
          },
          {
            name: "Tipos",
            url: "/sms/type",
            icon: "fab fa-yelp",
            attributes: {
              permission: { namespace: "sms", action: "type_list" },
            },
          },
        ],
      },
      {
        name: "Relatórios",
        url: "/sms/report",
        icon: "fas fa-print",
        attributes: { permission: { namespace: "sms" } },
      },
    ],
  },
];
