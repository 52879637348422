import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { GerencieModule } from '../app/apps/gerencie/gerencie.module';
import { environment } from '../environments/environment';

if (environment.production) {
    enableProdMode();
}

document.title = 'Gerencie';

window.addEventListener('dragover', event => event.preventDefault(), false);
window.addEventListener('drop', event => event.preventDefault(), false);

platformBrowserDynamic().bootstrapModule(GerencieModule);
